<template>
  <div>
    <div class="row">
      <div class="col-4">
        <img :src="newsArticle.image" alt="" class="w-100" />
      </div>

      <div class="col-8">
        {{ newsArticle.title }}
        <br />
        {{ newsArticle.category }} {{ newsArticle.publicationDate }}
      </div>
      <div class="col-12">
        <p v-html="newsArticle.content"></p>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  beforeCreate() {
    this.$store.dispatch("news/find", this.$route.params.id);
  },
  computed: {
    ...mapGetters("news", { newsArticle: "getNewsArticle" }),
  },
};
</script>
